exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-cms-js": () => import("./../../../src/pages/index-cms.js" /* webpackChunkName: "component---src-pages-index-cms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-js": () => import("./../../../src/pages/investment.js" /* webpackChunkName: "component---src-pages-investment-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pilot-js": () => import("./../../../src/pages/pilot.js" /* webpackChunkName: "component---src-pages-pilot-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-viva-js": () => import("./../../../src/pages/viva.js" /* webpackChunkName: "component---src-pages-viva-js" */)
}

